import { getAPI, postAPI } from '../API'
const globalState = {
  state: {
    searchState: {
      min_price: null,
      max_price: null,
      franchise_name: null,
      type: [],
      city: [],
      autopilot: 0,
      is_verified: 0
    }
  },
  mutations: {
    searchMutationToState(state, payload) {
      for (const property in payload) {
        if (
          typeof payload[property] === 'string' &&
          payload[property].includes(',')
        ) {
          payload[property] = payload[property].split(',').map(Number)
        }
      }

      payload.type = payload.type
        ? typeof payload.type === 'object'
          ? payload.type?.map(String)
          : [payload.type]
        : []

      payload.city = payload.city
        ? typeof payload.city === 'object'
          ? payload.city?.map(String)
          : [payload.city]
        : []
      payload.min_price = payload.min_price ? Number(payload.min_price) : null
      payload.max_price = payload.max_price ? Number(payload.max_price) : null
      state.searchState = {
        ...state.searchState,
        ...payload
      }
    },
    resetSearchFilter(state) {
      state.searchState.autopilot = 0
      state.searchState.is_verified = 0
    }
  },
  actions: {
    searchMarketBusiness(_, payload) {
      const queryParams = Object.entries(payload)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&')
      try {
        return getAPI(`index/business/search?${queryParams}`)
      } catch (error) {
        return error
      }
    }
  }
}
const landingPage = {
  state: {
    cityList: [],
    industryList: []
  },
  mutations: {
    setCityList(state, payload) {
      state.cityList = payload.map((a) => {
        return {
          label: a.name,
          value: String(a.id)
        }
      })
    },
    setIndustryList(state, payload) {
      state.industryList = payload.map((a) => {
        return {
          label: a.type,
          value: String(a.id)
        }
      })
    }
  },
  actions: {
    getCityList({ commit }) {
      try {
        getAPI('index/city').then((res) => {
          commit('setCityList', res.data)
        })
      } catch (error) {
        return error
      }
    },
    getIndustryList({ commit }) {
      try {
        getAPI('index/industry').then((res) => {
          commit('setIndustryList', res.data)
        })
      } catch (error) {
        return error
      }
    },
    getBannerList(_, payload) {
      try {
        return postAPI('index/banner', payload)
      } catch (error) {
        return error
      }
    },
    getAdsList(_, payload) {
      try {
        return postAPI('index/advbanner', payload)
      } catch (error) {
        return error
      }
    },
    getVerifiedBusiness(_, payload) {
      const queryParams = Object.entries(payload)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&')
      try {
        return getAPI(`index/business/verified?${queryParams}`)
      } catch (error) {
        return error
      }
    },
    getFeaturedBusiness() {
      try {
        return getAPI(`index/business/featured`)
      } catch (error) {
        return error
      }
    },
    getPopulerIndustry() {
      try {
        return getAPI(`index/business/category`)
      } catch (error) {
        return error
      }
    }
  }
}
const detailPage = {
  state: {},
  mutations: {},
  actions: {
    getBusinessDetail(_, payload) {
      try {
        return postAPI('index/business/detail', payload)
      } catch (error) {
        return error
      }
    },
    downloadBrochure(_, payload) {
      try {
        return postAPI('index/downloadbrochure', payload)
      } catch (error) {
        return error
      }
    },
    makeOrder(_, payload) {
      try {
        return postAPI('index/order/make', payload)
      } catch (error) {
        return error
      }
    }
  }
}
const blogPage = {
  state: {},
  mutations: {},
  actions: {
    getBlogList(_, payload) {
      try {
        return postAPI('index/blog/list', payload)
      } catch (error) {
        return error
      }
    },
    detailBlog(_, payload) {
      try {
        return postAPI('index/blog/detail', payload)
      } catch (error) {
        return error
      }
    }
  }
}
const campainPage = {
  state: {},
  mutations: {},
  actions: {
    getCampainDetail(_, payload) {
      try {
        return postAPI('index/campaign/detail', payload)
      } catch (error) {
        return error
      }
    },
    postCampainDetail(_, payload) {
      try {
        return postAPI('index/campaign/submit', payload)
      } catch (error) {
        return error
      }
    }
  }
}
export { landingPage, globalState, detailPage, blogPage, campainPage }
