/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */

const naiveThema = {
  common: {
    primaryColor: '#DD2B7B'
  },

  Input: {
    borderFocus: 'none',
    border: '1px solid rgba(148, 148, 150, 1)',
    borderRadius: '3px',
    heightMedium: '45px',
    borderHover: 'none',
    boxShadowFocus: 'none',
    fontSizeMedium: '13px',
    countTextColor: '#000',
    textColor: 'rgba(61, 63, 76, 1)',
    textColorDisabled: 'rgba(65, 63, 76, 0.7)',
    colorDisabled: 'rgba(243, 244, 246, 1)'
  },
  Empty: {
    iconSizeMedium: '60px',
    fontSizeMedium: '18px'
  },
  Form: {
    labelFontSizeLeftLarge: '14px',
    labelFontSizeTopMedium: '14px',
    labelTextColor: '#413F4C',
    feedbackHeightMedium: '15px',

    labelTextAlignHorizontal: 'left',
    feedbackTextColorError: 'rgba(242, 27, 74, 1)'
  },
  Checkbox: {
    borderRadius: '4px',
    fontSizeMedium: '14px',
    textColor: '#413F4C',
    fontSizeSmall: '12px'
  },
  Menu: {
    fontSize: '14px',
    itemIconColorInverted: '#000',
    itemTextColorInverted: '#000',
    itemIconColorHoverInverted: '#fff',
    itemColorHoverInverted: '#DD2B7B',
    itemTextColorHoverInverted: '#fff',
    borderRadius: '7px',
    itemIconColorCollapsedInverted: '#000',
    itemHeight: '50px'
  },
  DatePicker: {
    panelBorderRadius: '10px',
    itemBorderRadius: '30px',
    calendarTitleFontWeight: '500',

    peers: {
      Input: {
        border: '1px solid #9f9f9f9f'
      }
    }
  },

  Carousel: {
    dotColorActive: '#DD2B7B',
    dotLineWidth: '10px',
    dotLineWidthActive: '20px',
    dotColor: '#9f9f9f9f'
  },
  Tabs: {
    tabFontSizeMedium: '16px',
    tabFontWeight: '400',
    tabFontWeightActive: '600',
    tabBorderRadius: '30px',
    tabPaddingMediumBar: '5px 7px',
    tabTextColorActiveBar: '#DD2B7B',
    tabTextColorBar: '#949496'
  },
  Card: {
    borderRadius: '10px'
  },
  InputNumber: {
    peers: {
      Input: {
        borderRadius: '4px',
        border: '1px solid rgba(71, 71, 71, 0.4)',
        fontSizeMedium: '14px'
      }
    }
  },
  Select: {
    peers: {
      InternalSelection: {
        borderFocus: 'none',
        border: '1px solid rgba(223, 223, 225, 1)',
        borderRadius: '5px',
        heightMedium: '40px',
        heightSmall: '30px',
        heightLarge: '45px',
        borderHover: 'none',
        boxShadowFocus: 'none',
        fontSizeMedium: '13px',
        textColorDisabled: 'rgba(65, 63, 76, 0.7)'
      }
    }
  },
  Switch: {
    railWidthMedium: '60px',
    railHeightMedium: '30px',
    buttonHeightMedium: '25px',
    buttonWidthMedium: '25px'
  },
  Collapse: {
    titleFontSize: '15px',
    titleFontWeight: '600',
    titleTextColor: '#413F4C'
  },
  LoadingBar: {
    colorLoading: '#000'
  },

  Modal: {
    peers: {
      Dialog: {
        peers: {
          Button: {
            colorHoverPrimary: '#DD2B7B',
            colorPressedPrimary: '#DD2B7B',
            borderPressedPrimary: '#DD2B7B',
            borderHoverPrimary: '#DD2B7B',
            borderRadiusMedium: '7px',
            textColorGhostHoverWarning: '#DD2B7B'
          }
        },

        borderRadius: '7px'
      }
    }
  },
  Dialog: {
    borderRadius: '9px',

    peers: {
      Button: {
        colorWarning: '#DD2B7B',
        colorHoverWarning: '#DD2B7B',
        borderWarning: '#DD2B7B',
        borderHoverWarning: '#DD2B7B',
        colorPressedWarning: '#DD2B7B',
        borderRadiusSmall: '7px',
        paddingSmall: '10px 15px',
        heightSmall: '35px',
        colorDisabledWarning: '#DD2B7B',
        borderDisabledWarning: '#DD2B7B'
      }
    }
  },

  DataTable: {
    thColor: '#fff',
    tdTextColor: 'rgba(65, 63, 76, 1)',
    fontSizeMedium: '14px',
    tdColorStriped: 'rgba(235, 0, 139, 0.03)',
    peers: {
      Button: {
        colorHoverPrimary: '#DD2B7B',
        colorPressedPrimary: '#DD2B7B',
        borderPressedPrimary: '#DD2B7B',
        borderHoverPrimary: '#DD2B7B'
      }
    },
    tdPaddingMedium: '15px 20px',
    thPaddingMedium: '15px 20px'
  },
  Pagination: {
    itemColorHover: '#DD2B7B',
    itemTextColorHover: '#fff'
  },
  Notification: {
    borderRadius: '10px',
    titleFontSize: '16px',
    descriptionFontSize: '16px',
    fontSize: '14px',
    padding: '30px 15px'
  },
  Dropdown: {
    fontSizeMedium: '12px'
  }

  // ...
}

const themeTabsDektop = {
  Tabs: {
    tabFontSizeMedium: '16px',
    tabFontWeight: '400',
    tabFontWeightActive: '600',
    tabBorderRadius: '30px',
    tabPaddingMediumBar: '5px 7px'
  }
}

const themeTabsMobile = {
  Tabs: {
    tabFontSizeMedium: '12px',
    tabFontWeight: '400',
    tabFontWeightActive: '600',
    tabBorderRadius: '30px',
    tabPaddingMediumBar: '5px 7px',
    tabGapMediumBar: '10px'
  }
}
/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
const themaInput = {
  Input: {
    textColor: '#fff',
    border: 'none',
    paddingMedium: '5px',
    heightMedium: '30px',
    fontSizeMedium: '14px',
    colorDisabled: '#fff',
    textColorDisabled: '#f0f2f0',
    borderDisabled: 'none'
  },
  Select: {
    peers: {
      InternalSelection: {
        paddingSingle: '5px',
        placeholderColor: '#fff',
        paddingMultiple: '5px',
        border: 'none',
        borderFocus: 'unset',
        borderActive: 'unset',
        boxShadowFocus: 'unset',
        boxShadowActive: 'unset',
        textColor: '#fff',
        arrowColor: '#fff',
        fontSizeMedium: '14px',
        borderRadius: 0,
        textColorDisabled: '#f0f2f0'
      }
    }
  },
  Tag: {
    closeIconColor: '#fff',
    border: 'none',
    closeIconSizeMedium: '12px',
    closeColorHover: 'none',
    closeIconColorHover: '#fff'
  }
}
/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
const themeInputSearch = {
  Input: {
    border: 'none',
    fontSizeMedium: '16px'
  }
}

const popOver = {
  Popover: {
    borderRadius: '7px',
    padding: '15px'
  }
}
/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
const searchBusinessConfig = {
  InputNumber: {
    peers: {
      Input: {
        border: 'none',
        paddingMedium: '0',
        placeholderColor: 'rgba(148, 148, 150, 1)',
        fontSizeMedium: '16px'
      }
    }
  },
  Select: {
    peers: {
      InternalSelection: {
        border: 'none',
        borderActive: 'none',
        borderFocus: 'none',
        arrowColor: 'none',
        borderHover: 'none',
        boxShadowActive: 'none',
        fontSizeMedium: '16px',
        paddingMultiple: '5px 0px 0px 0px',
        placeholderColor: 'rgba(148, 148, 150, 1)',
        peers: {
          Popover: {
            padding: '15px 10px',
            space: '40px',
            borderRadius: '7px'
          }
        }
      },
      InternalSelectMenu: {
        paddingMedium: '12px',
        optionPaddingMedium: '10px'
      }
    }
  },
  Tag: {
    padding: '10px',
    borderRadius: '4px'
  },
  Input: {
    borderRadius: '6px',
    placeholderColor: 'rgba(148, 148, 150, 1)',
    fontSizeMedium: '16px',
    border: 'none'
  },
  Checkbox: {
    sizeLarge: '27px'
  }
}
export {
  naiveThema,
  themeTabsDektop,
  themeTabsMobile,
  themaInput,
  themeInputSearch,
  popOver,
  searchBusinessConfig
}
