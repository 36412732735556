<template>
  <n-config-provider :theme-overrides="naiveThema">
    <n-notification-provider :max="1">
      <div id="nav"></div>
      <metainfo>
        <template v-slot:title="{ content }">{{
          content ? `${content}` : 'Rintisan'
        }}</template>
      </metainfo>
      <router-view />
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent, onBeforeMount } from '@vue/runtime-core'
import moment from 'moment'
import { mapActions } from './store/methodVuex'
import { naiveThema } from './styles/configNaiveThema'
export default defineComponent({
  name: 'business-dashboard',
  setup() {
    const {
      interceptorRequest,
      interceptorResponse,
      getCityList,
      getIndustryList
    } = mapActions()
    onBeforeMount(() => {
      interceptorRequest()
      interceptorResponse()
      getCityList()
      getIndustryList()
    })
    return {
      naiveThema
    }
  },
  metaInfo() {
    return {
      title:
        'Rintisan - Platform Bisnis dan Marketplace Franchise No 1 di Indonesia',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
        { name: 'author', content: 'rintisan.co.id' },
        {
          name: 'keywords',
          content: `franchise,franchisor,peluang bisnis,waralaba,murah,jakarta,bandung,bogor,tangerang,surabaya,bisnis makanan,lisensi,rintisan,indonesia,mitra,kemitraan`
        },
        {
          hid: 'description',
          name: 'description',
          content:
            'Rintisan adalah platform dan marketplace franchise atau waralaba, lisensi bisnis dan business opportunity terbaik di Indonesia'
        },
        {
          property: 'og:title',
          content: 'rintisan'
        },
        { property: 'og:site_name', content: 'rintisan.co.id' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'http://rintisan.co.id' },
        {
          property: 'article:modified_time',
          content: moment().format('MMMM Do YYYY, h:mm:ss a')
        }
      ],
      htmlAttrs: { lang: 'id-id' }
    }
  }
})
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
*,
html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 639px) {
  .n-date-panel {
    display: inline-block;
  }
  .ant-table-striped th.ant-table-cell:last-child {
    background: rgba(235, 0, 139, 0.1);
  }
  .ant-table-striped .table-striped td:last-child {
    background: rgba(235, 0, 139, 0.03);
  }
}
</style>
