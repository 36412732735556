import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  createMetaManager,
  defaultConfig,
  plugin as metaPlugin
} from 'vue-meta'
import './styles/app.css'
import VueLazyload from 'vue-lazyload'

import {
  NConfigProvider,
  create,
  NCarousel,
  NCarouselItem,
  NSwitch,
  NInputNumber,
  NInput,
  NSelect,
  NInputGroup,
  NCheckbox,
  NCollapse,
  NCollapseItem,
  NCheckboxGroup,
  NImageGroup,
  NImage,
  NEmpty,
  NSkeleton,
  NSpace,
  NCard,
  NModal,
  NNotificationProvider,
  NForm,
  NFormItem,
  NSpin
} from 'naive-ui'

import './registerServiceWorker'
const naiveUI = create({
  components: [
    NConfigProvider,
    NCarousel,
    NCarouselItem,
    NSwitch,
    NInputNumber,
    NInput,
    NSelect,
    NInputGroup,
    NCheckbox,
    NCollapse,
    NCollapseItem,
    NCheckboxGroup,
    NImageGroup,
    NImage,
    NEmpty,
    NSkeleton,
    NSpace,
    NCard,
    NModal,
    NNotificationProvider,
    NForm,
    NFormItem,
    NSpin
  ]
})
const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true }
})
const app = createApp(App)
  .use(store)
  .use(router)
  .use(metaManager)
  .use(metaPlugin)
  .use(naiveUI)
  .use(VueLazyload, {})

app.mount('#app')
