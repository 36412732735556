import axios from 'axios'
// VUE_APP_TOKEN_DEFAULT_PROD
// VUE_APP_IMAGE_URL_PROD
// VUE_APP_URL_PROD
const isProd =
  window.location.origin === 'https://rintisan.co.id' ||
  window.location.origin === 'https://www.rintisan.co.id'
const pathUrl = isProd
  ? `${process.env.VUE_APP_URL_PROD}`
  : `${process.env.VUE_APP_URL_DEV}`
const pathImage = isProd
  ? `${process.env.VUE_APP_IMAGE_URL_PROD}`
  : `${process.env.VUE_APP_IMAGE_URL_DEV}`
const tokenDefault = isProd
  ? `${process.env.VUE_APP_TOKEN_DEFAULT_PROD}`
  : `${process.env.VUE_APP_TOKEN_DEFAULT_DEV}`

const getAPI = (urlAPI) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${pathUrl}/v1/marketplace/${urlAPI}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

const postAPI = (urlAPI, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${pathUrl}/v1/marketplace/${urlAPI}`, payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}

export { getAPI, postAPI, tokenDefault, pathImage, isProd }
