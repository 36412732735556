import { tokenDefault } from '../API'
import axios from 'axios'
import router from '../../router'
const interCeptor = {
  actions: {
    interceptorRequest() {
      axios.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          config.headers.Authorization = `Bearer ${tokenDefault}`

          return config
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error)
        }
      )
    },
    interceptorResponse(context) {
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (
            error.response.status === 401 &&
            error.response.statusText === 'Unauthorized'
          ) {
            context.commit('setAccess403', 3)
            router.push({
              name: '403',
              params: {
                linkName: 'repackgeingpage',
                errorCode: '401'
              }
            })
          } else if (
            error.response.status === 403 &&
            error.response.data.meta.message === 'No Access'
          ) {
            context.commit('setAccess403', 2)
            router.push({
              name: '403',
              params: {
                linkName: 'privilegeforbidden',
                errorCode: '403'
              }
            })
          }
          return Promise.reject(error)
        }
      )
    }
  }
}

export { interCeptor }
